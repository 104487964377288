import React from 'react';
import styled from 'styled-components';
import { Card, CardContent as MuiCardContent, Typography, Divider } from '@material-ui/core';
import { LocationOn } from '@material-ui/icons';

export const RestaurantList = (props) => {
  const data = props.results;

  const firstProperty = Object.keys(data.body)[0];
  const restaurants = data.body[firstProperty];
 
  return (
    <Container>
      {restaurants.map((restaurantObj, index) => (
        <StyledCard key={index} backgroundColor={getCardBackgroundColor(index)}>
          <CardContent>
            <Title>{restaurantObj.name}</Title>
            <Description>{restaurantObj.description}</Description>
            <Divider />
            <Info>
              <div>
                <InfoItem style={{ paddingBottom: '5px' }}>Rating: {restaurantObj.rating}</InfoItem>
              </div>
              <InfoItem>Price: {restaurantObj.price}</InfoItem>
            </Info>
            <Info>
              <InfoItem>Best Items: {restaurantObj.best_food_items}</InfoItem>
            </Info>
            <Reviews>
              <ReviewTitle>Top Reviews:</ReviewTitle>
              <ul>
                {restaurantObj.top_2_reviews.map((review, reviewIndex) => (
                  <ReviewItem key={reviewIndex}>{review.review}</ReviewItem>
                ))}
              </ul>
            </Reviews>
            <Info>
              <LocationOnIcon />
              <MapLink href={restaurantObj.map_link} target="_blank" rel="noopener noreferrer">
                View on Map
              </MapLink>
            </Info>
          </CardContent>
        </StyledCard>
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
`;

const StyledCard = styled(Card)`
  width: 80%;
  border-radius: 20px;
  margin-bottom: 20px;
`;

const CardContent = styled(MuiCardContent)`
  padding: 20px;
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
`;

const Title = styled(Typography).attrs({
  variant: 'h5',
  component: 'h1',
})`
  margin-bottom: 8px;
  display: flex;
  padding-bottom: 5px;
`;

const Description = styled(Typography).attrs({
  gutterBottom: true,
})`
  margin-bottom: 8px;
`;

const Info = styled.div`
  margin-top: 8px;
`;

const InfoItem = styled(Typography).attrs({
  variant: 'body2',
  component: 'p',
})`
  display: inline-block;
  margin-right: 10px;
`;

const Reviews = styled.div`
  margin-top: 8px;
`;

const ReviewTitle = styled(Typography).attrs({
  variant: 'body2',
  component: 'p',
})`
  margin-bottom: 4px;
`;

const ReviewItem = styled.li`
  font-size: 12px;
  font-weight: 400;
`;

const LocationOnIcon = styled(LocationOn)`
  vertical-align: middle;
  margin-right: 5px;
`;

const MapLink = styled.a`
  text-decoration: none;
  color: #1976d2;
`;

const getCardBackgroundColor = (index) => {
  const colors = ['#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff'];
  return colors[index % colors.length];
};

export default RestaurantList;
