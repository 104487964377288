import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './App.css';
import './styles.css';
import SearchPage from './search-page';
import DetailsPage from './details-page';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<SearchPage />}></Route>
        <Route path="/details-page" element={<DetailsPage />}></Route>
      </Routes>
    </Router>
  );
}

export default App;