import React, { useState, useEffect } from 'react';
import promptingEatsImage from './prompting-eats.png'; 
import './styles.css'; // Import CSS file for styling

const LoadingIndicator = () => {
  const [text, setText] = useState('Hang tight');

  useEffect(() => {
    const interval = setInterval(() => {
      const texts = ['Hang tight', 'We are getting you the best results', 'Almost there'];
      const nextIndex = (texts.indexOf(text) + 1) % texts.length;
      setText(texts[nextIndex]);
    }, 5000);

    return () => clearInterval(interval);
  }, [text]);

  return (
    <div className="loading-page">
      <div className="background-image">
        <img
          src={promptingEatsImage}
          alt="Loading"
        />
        <p className="loading-text">{text}<span className="blinking-ellipsis">...</span></p>
      </div>
    </div>
  );
};

export default LoadingIndicator;
