import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import RestaurantList from './restaurant-list';
import LoadingIndicator from './loading-indicator';
import './styles.css';
import axios from 'axios';
import homeLogo from './home_logo.jpg'; // Import the image

const DetailsPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState(location.state?.inputValue || '');
  const [isLoading, setIsLoading] = useState(true);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [address, setAddress] = useState('');
  const apiKey = "585b8cd6831344dd9e79255d34d5ff26"

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLatitude(latitude);
          setLongitude(longitude);
        },
        (error) => {
          console.log("Geolocation error:", error.message);
        }
      );
    }
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  const getAddress = async () => {
    if (latitude && longitude) {
      try {
        const response = await axios.get(
          `https://api.opencagedata.com/geocode/v1/json?q=${latitude}+${longitude}&key=${apiKey}`
        );
        if (response.data.results.length > 0) {
          const fullAddress = response.data.results[0].components;
          const city = fullAddress.city;
          const state = fullAddress.state;
          const county = fullAddress.county;
          const postcode = fullAddress.postcode
          if(city !== undefined){
            setAddress(`city:${city}-state:${state}-postcode:${postcode}`);
          } else {
            setAddress(`county:${county}-state:${state}-postcode:${postcode}`)
          }
        } else {
          console.log('No results found for address.');
        }
      } catch (error) {
        console.error('Error getting address:', error);
      }
    } else {
      console.error('Latitude and longitude are not set.');
    }
  };

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
    if(address === ''){
      getAddress()
    }
  };

  const handleSearchClick = async () => {
    setIsLoading(true); // Set loading to true when search button is clicked
    console.log('Loading started...');

    try {
      const response = await axios.post(
        'https://o87x94746l.execute-api.us-east-1.amazonaws.com/alpha-test',
        { input: searchTerm + " ; " + address},
        {
          headers: {
            'x-api-key': process.env.REACT_APP_API_KEY,
            'Content-Type': 'application/json'
          },
        }
      );

      setIsLoading(false); // Set loading to false after receiving the response
      console.log('Loading ended.');

      navigate('/details-page', {
        state: {
          inputValue: searchTerm,
          results: response.data,
        },
      });
    } catch (error) {
      setIsLoading(false); // Set loading to false after receiving the response
      console.error('Error calling API:', error);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearchClick();
    }
  };

  return (
    <div>
      {isLoading ? (
        <LoadingIndicator text="Hang tight...." />
      ) : (
        <div>
          <div className="details-background"></div>
          <header className="details-sticky-header">
            <div className="details-search-container">
              <button onClick={() => navigate('/')} className="details-home-button">
                <img src={homeLogo} alt="Home" className="details-home-logo" />
              </button>
              <div className="input-search">
                <input
                  type="text"
                  placeholder="Search..."
                  value={searchTerm}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                  className="details-search-input"
                />
                <button onClick={handleSearchClick} className="details-search-button">
                  <FontAwesomeIcon icon={faSearch} />
                </button>
              </div>
            </div>
          </header>
          <div style={{ paddingTop: '60px', minHeight: '100vh' }}>
            <RestaurantList results={location.state?.results} />
          </div>
        </div>
      )}
    </div>
  );
};

export default DetailsPage;
