import { FaSearch } from 'react-icons/fa';
import React, { useState, useEffect } from 'react';
import './styles.css';
import logo from './prompting-eats.png';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import LoadingIndicator from './loading-indicator';

const SearchPage = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCuisine, setSelectedCuisine] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [address, setAddress] = useState('');
  const navigate = useNavigate();
  const apiKey = "585b8cd6831344dd9e79255d34d5ff26"

  const prompts = [
    "Popular steak places in New York",
    "Vegan restaurants in Boston with a modern ambiance",
    "Best Indian restaurants in San Francisco",
    "Gluten-free cafes in Chicago"
  ];

  const getAddress = async () => {
    if (latitude && longitude) {
      try {
        const response = await axios.get(
          `https://api.opencagedata.com/geocode/v1/json?q=${latitude}+${longitude}&key=${apiKey}`
        );
        console.log('Address API response:', response.data);
        if (response.data.results.length > 0) {
          const fullAddress = response.data.results[0].components;
          const city = fullAddress.city;
          const state = fullAddress.state;
          const county = fullAddress.county;
          const postcode = fullAddress.postcode
          if(city !== undefined){
            setAddress(`city:${city}-state:${state}-postcode:${postcode}`);
          } else {
            setAddress(`county:${county}-state:${state}-postcode:${postcode}`)
          }
        } else {
          console.log('No results found for address.');
        }
      } catch (error) {
        console.error('Error getting address:', error);
      }
    } else {
      console.error('Latitude and longitude are not set.');
    }
  };

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLatitude(latitude);
          setLongitude(longitude);
        },
        (error) => {
          console.log("Geolocation error:", error.message);
        }
      );
    }
  }, []);

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
    if(!address){
      getAddress()
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearchClick();
    }
  };

  const handleSearchClick = async (term = searchTerm) => {
    setIsLoading(true);

    try {
      const response = await axios.post(
        'https://o87x94746l.execute-api.us-east-1.amazonaws.com/alpha-test',
        { input: term + " ; " + address },
        {
          headers: {
            'x-api-key': process.env.REACT_APP_API_KEY,
            'Content-Type': 'application/json'
          },
        }
      );

      setIsLoading(false);
      navigate('/details-page', {
        state: {
          inputValue: term,
          address: address,
          results: response.data,
        },
      });
    } catch (error) {
      setIsLoading(false);
      console.error('Error calling API:', error);
    }
  };

  const handleCuisinePillClick = async (cuisine) => {
    setSelectedCuisine(cuisine);
    setSearchTerm(cuisine);
    handleSearchClick(cuisine);
  };

  return (
    <div>
      {isLoading ? (
        <LoadingIndicator text="Hang tight...." />
      ) : (
        <div className="background-image-container">
          <div className="logo-container">
            <img src={logo} alt="Logo" className="logo" />
          </div>
          <div className="input-box-div">
            <input
              type="text"
              placeholder="Feeling hungry? Explore our tempting suggestions below or get creative with your own!"
              className="input-box"
              value={searchTerm}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
            />
            <button type="submit" className="search-button" onClick={() => handleSearchClick()}>
              <FaSearch />
            </button>
          </div>
          <div className="cuisine-pills">
            {prompts.map((res, index) => (
              <span
                key={index}
                className={`cuisine-pill ${selectedCuisine === res ? 'active' : ''}`}
                onClick={() => handleCuisinePillClick(res)}
              >
                {res}
              </span>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchPage;
